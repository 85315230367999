import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {ApiService} from '../base/api.service';
import {ApiEndpointsService} from '../base/api-endpoints.service';
import {ServerResponse} from '../../interfaces/base/server.response';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ReportCreated} from '../../interfaces/report/report-created';

@Injectable()

export class WebformService {

  public normalForm = this.fb.group({
    form_filling_start_at: null,
    report_type_id: new FormControl(1, {validators: Validators.required}),
    surrounding_id: new FormControl(null, {validators: Validators.required}),
    head_category_id: new FormControl(null, {validators: Validators.required}),
    main_category_id: new FormControl(null, {validators: Validators.required}),
    sub_category_id: new FormControl(null),
    description: new FormControl('', {validators: Validators.maxLength(5000)}),
    noticed_at: null,
    noticed_at_fix: null,
    suspect_known: new FormControl(null, {validators: Validators.required}),
    suspect_type: null,
    suspect_name: null,
    suspect_company_id: null,
    suspect_location: null,
    suspect_postalcode: null,
    suspect_house_number: null,
    suspect_house_number_addition: null,
    suspect_streetname: null,
    suspect_city: null,
    suspect_municipality: null,
    suspect_lat: null,
    suspect_lng: null,
    attachments: this.fb.array([]),
    attachmentsContainer: [''],
    report_location: new FormControl(null, {validators: Validators.required}),
    report_location_description: new FormControl('', {validators: Validators.maxLength(500)}),
    report_postalcode: null,
    report_streetname: null,
    report_house_number: null,
    report_house_number_addition: null,
    report_city: null,
    report_municipality: null,
    report_lat: null,
    report_lng: null,
    same_as_reporter_address: new FormControl(null, {validators: Validators.required, nonNullable: true}),
    reporter_anonymous: new FormControl(false, {validators: Validators.required, nonNullable: true}),
    reporter_type_id: new FormControl(null, {validators: Validators.required}),
    reporter_organisation: null,
    reporter_prefix: new FormControl('Neutraal', {validators: Validators.required, nonNullable: true}),
    reporter_initials: null,
    reporter_middlename: null,
    reporter_lastname: null,
    reporter_location: null,
    reporter_postalcode: null,
    reporter_house_number: null,
    reporter_house_number_addition: null,
    reporter_streetname: null,
    reporter_city: null,
    reporter_municipality: null,
    reporter_lat: null,
    reporter_lng: null,
    reporter_email: new FormControl(null, {validators: [Validators.required, Validators.email], nonNullable: true}),
    reporter_phone: new FormControl(null, {validators: [Validators.pattern(/^\d{0,10}$/)]}),
    reporter_notify: new FormControl(true, {validators: Validators.required, nonNullable: true}),
  });

  public companyForm = this.fb.group({
    form_filling_start_at: null,
    report_type_id: new FormControl(2, {validators: Validators.required, nonNullable: true}),
    head_category_id: new FormControl(5, {validators: Validators.required, nonNullable: true}),
    description: new FormControl('', {validators: [Validators.required, Validators.maxLength(5000)], nonNullable: true}),
    noticed_at: new FormControl(null, {validators: Validators.required}),
    end_at: new FormControl(null, {validators: Validators.required}),
    report_location_description: new FormControl('', {validators: Validators.maxLength(500), nonNullable: true}),
    report_location: new FormControl(null, {validators: Validators.required}),
    report_postalcode: null,
    report_streetname: null,
    report_house_number: null,
    report_house_number_addition: null,
    report_city: null,
    report_municipality: null,
    report_lat: null,
    report_lng: null,
    suspect_known: new FormControl(true, {validators: Validators.required}),
    suspect_type: 'Bedrijf',
    suspect_name: new FormControl(null, {validators: Validators.required}),
    suspect_company_id: null,
    suspect_location: new FormControl(null, {validators: Validators.required}),
    suspect_postalcode: null,
    suspect_house_number: null,
    suspect_house_number_addition: null,
    suspect_streetname: null,
    suspect_city: null,
    suspect_municipality: null,
    suspect_lat: null,
    suspect_lng: null,
    reporter_type_id: new FormControl(2, {validators: Validators.required}),
    reporter_prefix: new FormControl('Neutraal', {validators: Validators.required, nonNullable: true}),
    reporter_initials: null,
    reporter_middlename: null,
    reporter_lastname: new FormControl(null, {validators: Validators.required}),
    reporter_email: new FormControl(null, {validators: [Validators.required, Validators.email], nonNullable: true}),
    reporter_phone: new FormControl(null, {validators: [Validators.required, Validators.pattern(/^\d{0,10}$/)]}),
    reporter_notify: new FormControl(true, {validators: Validators.required, nonNullable: true}),
  });

  public formType: 'normal' | 'company' = null;
  public checkForm: boolean = false;
  public report: ReportCreated = null;

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
              private fb: FormBuilder,
  ) {
  }

  get(id: string): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('webform.reports-read', {':unique_number': id}));
  }

  saveNormalReport(formData: any): Observable<ReportCreated | boolean> {
    return new Observable<ReportCreated | boolean>((o: Subscriber<ReportCreated | boolean>): void => {
      this.api.post(this.apiEndpoints.get('webform.reports-normal'), formData).subscribe(
        (response: ServerResponse): void => {
          this.report = response.data;
          o.next(response.data);
        },
        (): void => o.next(false)
      );
    });
  }

  saveCompanyReport(formData: any): Observable<ReportCreated | boolean> {
    return new Observable<ReportCreated | boolean>((o: Subscriber<ReportCreated | boolean>): void => {
      this.api.post(this.apiEndpoints.get('webform.reports-company'), formData).subscribe(
        (response: ServerResponse): void => {
          this.report = response.data;
          o.next(response.data);
        },
        (): void => o.next(false)
      );
    });
  }

  forwardFailedReport(formData: any): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>): void => {
      this.api.post(this.apiEndpoints.get('webform.reports-failed'), formData).subscribe(
        (response: ServerResponse): void => {
          if (!this.api.isErrorResponse(response)) {
            o.next(true);
          }
        },
        (): void => o.next(false)
      );
    });
  }
}

import {Injectable} from '@angular/core';
import {Control, Icon, LatLng, latLng, LayerGroup, Map, MapOptions, Marker, MarkerOptions, tileLayer, TileLayer} from 'leaflet';
import Layers = Control.Layers;

@Injectable()

export class LeafletMapService {

  public mapLayerStandard: TileLayer = tileLayer('https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/standaard/EPSG:3857/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: 'Kaartgegevens &copy; <a href="https://www.kadaster.nl">Kadaster</a>',
    minZoom: 6,
    maxZoom: 19,
  });

  public mapLayerSatellite: TileLayer = tileLayer('https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/Actueel_orthoHR/EPSG:3857/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: 'Kaartgegevens &copy; <a href="https://www.kadaster.nl">Kadaster</a>',
    minZoom: 6,
    maxZoom: 19,
  });
  public center: LatLng = latLng(51.80, 4.94);
  public loadingOptions: any = {
    position: 'topleft',
  };
  public baseMaps = {
    'Achtergrond kaart': this.mapLayerStandard,
    'Luchtfoto kaart': this.mapLayerSatellite
  };
  public markerLayer: LayerGroup = new LayerGroup();
  public mapContainer: Map = null;
  public singleMarker: Marker = null;
  private defaultZoom: number = 8;
  public mapOptions: MapOptions = {
    layers: [this.mapLayerStandard],
    zoom: this.defaultZoom,
    center: this.center
  };
  private defaultZoomed: number = 17;

  constructor() {
  }

  init(mapContainer: Map): void {
    this.mapContainer = mapContainer;
    this.mapContainer.addLayer(this.markerLayer);
    this.mapContainer.addControl(new Layers(this.baseMaps));
    this.updateMarker([this.center.lat, this.center.lng], false);

    this.mapContainer.addEventListener('unload', (): void => {
      this.mapContainer.removeEventListener('unload');
      this.mapContainer = null;
    });
  }

  marker(): MarkerOptions {
    return {
      icon: new Icon({
        iconUrl: '/assets/img/marker-icon.png',
        iconSize: [64, 64],
        iconAnchor: [32, 64],
      }),
      draggable: false,
    };
  }

  updateMarker(coords: [number, number], updateView: boolean = true): void {
    if (this.singleMarker) {
      this.singleMarker.setLatLng({lat: coords[0], lng: coords[1]});
    } else {
      this.singleMarker = new Marker({lat: coords[0], lng: coords[1]}, this.marker());
      this.markerLayer.addLayer(this.singleMarker);
    }

    if (updateView) {
      this.mapContainer.setView(coords, this.defaultZoomed);
    }
  }

  clear(): void {
    if (this.mapContainer !== null) {
      this.updateMarker([this.center.lat, this.center.lng], false);
      this.mapContainer.setView(this.center, this.defaultZoom);
    }
  }
}
